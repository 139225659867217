import React, { FC, useImperativeHandle, useEffect, useRef } from 'react';
import '@google/model-viewer/dist/model-viewer';
export interface IModelViewerComponentProps {
  modelUrl: string;
  environmentImage: string;
  onHasARSupport: any;
  ref: any;
}

const ModelViewerComponent: FC<IModelViewerComponentProps> = React.forwardRef((
  props: IModelViewerComponentProps, ref
) => {
  const modelViewerStyle = {
    width: '100%',
    height: '100%'
  };
  const modelViewer = useRef<any>();
  const activateAR = () => {
    modelViewer.current.activateAR();
  };

  const hasARSupport = () => {
    props.onHasARSupport();
  };

  useImperativeHandle(ref, () => ({
    activateAR
  }));

  useEffect(() => {
    modelViewer.current.addEventListener('model-visibility', (event: any) => {
      if(modelViewer.current.canActivateAR) {
        hasARSupport();
      }
    });
  });

  return(
    <model-viewer
      ref={modelViewer}
      style={modelViewerStyle}
      src={`${props.modelUrl}.glb`}
      ios-src={`${props.modelUrl}.usdz`}
      environment-image={props.environmentImage}
      ar-modes="webxr scene-viewer quick-look"
      ar-placement="floor"
      ar-scale="fixed"
      ar-status="not-presenting"
      autoplay="true"
      camera-controls="true"
      ar="true"
      loading="eager"
      data-js-focus-visible="true"
      align-model="origin center">
      <div slot="ar-button">
      </div>
    </model-viewer>
  );
})

export default ModelViewerComponent;
