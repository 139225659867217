import { FC, ReactElement, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { getModelConfig, ModelColor, ModelType } from '../config';
import ModelViewerComponent from '../components/model-viewer-component';
import InfoPopup from '../components/info-popup';
import { Trans } from 'react-i18next';
import theme, { SecondaryButton } from '../theme';

export interface IAppColorsProps {
  modelId: string;
  language: string;
  color?: ModelColor;
  descriptionHidden?: boolean;
  instructionHidden?: boolean;
  colorPickerHidden?: boolean;
  viewDEWhiteOnly?: boolean;
}

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: `0 ${theme.padding}px ${theme.padding}px`,
    textAlign: 'center',
  },
  logo: {
    marginTop: `${theme.paddingTop}px`,
    '& img': {
      height: '70px'
    },
  },
  infoButton: {
    width: `${theme.buttonWidth}px`,
    height: `${theme.buttonHeight}px`,
    position: 'absolute',
    top: `${theme.paddingTop}px`,
    right: `${theme.padding}px`,
    border: '0',
    borderRadius: theme.buttonBorderRadius,
    backgroundColor: theme.vorwerkColor,
    "& img": {
      width: '10px',
      marginTop: '2px'
    }
  },
  imprintButtonContainer: {
    position: 'absolute',
    width: '100%',
    textAlign: 'right',
    top: `${theme.buttonWidth + theme.paddingTop*2 - 5}px`,
    right: `${theme.padding}px`
  },
  imprintButton: {
    extend: SecondaryButton,
    width: `${theme.buttonWidth}px`,
    height: `${theme.buttonHeight}px`,
    "& img": {
      width: '24px',
      marginTop: '2px'
    }
  },
  description: {
    marginTop: '40px',
    fontSize: '18px',
    fontFamily: 'Vorwerk',
    fontWeight: 'lighter',
  },
  instruction: {
    marginTop: '18px',
    padding: '0 12px',
    fontSize: '17px',
    fontFamily: 'Vorwerk',
  },
  colorsPicker: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    '& button': {
      width: '50px',
      height: '50px',
      border: '1px solid #000',
      borderRadius: '50%',
      "& img": {
        width: '50px',
        height: '50px',
      }
    },
  },
  buttonColor: {
    marginLeft: '32px',
  },
  arButton: {
    display: 'flex',
    marginTop: '20px',
    flexDirection: 'column',
    maxWidth: '100%',
    padding: '8px 18px',
    border: '0',
    borderRadius: theme.buttonBorderRadius,
    color: 'white',
    fontFamily: 'Vorwerk',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: theme.vorwerkColor,
  },
  modelViewerContainer: {
    width: '100%',
    height: '20%',
    flex: '1',
    marginTop: '15px',
  }
});

const AppColors: FC<IAppColorsProps> = (props: IAppColorsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [colorState, setColorState] = useState<ModelColor>(
    props.color || ModelColor.BLACK
  );
  const [infoPopupIsOpen, setInfoPopupIsOpen] = useState<boolean>(false);
  const [imprintPopupIsOpen, setImprintPopupIsOpen] = useState<boolean>(false);
  const [hasARSupport, setHasARSupport] = useState<boolean>(false);

  const config = getModelConfig(props.modelId, props.language);
  
  const onColorButtonClick = (color: ModelColor) => {
    setColorState(color);
  };

  const onInfoButtonClick = () => {
    setInfoPopupIsOpen(true);
  };

  const onInfoPopupClose = () => {
    setInfoPopupIsOpen(false);
  };

  const onImprintButtonClick = () => {
    setImprintPopupIsOpen(true);
  };

  const onImprintPopupClose = () => {
    setImprintPopupIsOpen(false);
  };

  const modelViewer = useRef<any>();
  const onARButtonClick = () => {
    modelViewer.current.activateAR();
  };

  const onHasARSupport = () => {
    setHasARSupport(true);
  }

  let renderedColorsLength: number = 0;
  function renderColorButton(color: ModelColor): ReactElement {
    renderedColorsLength++;
    const isCustomIcon: boolean = config.iconsUrl 
      ? config.iconsUrl[color]  ? true : false 
      : false;
    console.log("---------------------------------------");
    console.log(isCustomIcon);
    console.log(config.iconsUrl?.[color]);
    return (
      <button 
        className={renderedColorsLength > 1 ? classes.buttonColor : ''} 
        onClick={() => onColorButtonClick(color)}
        style={{
          backgroundColor: isCustomIcon ? 'none' : color
        }}
      >
        {isCustomIcon ? 
          <img src={config?.iconsUrl?.[color]} alt={color} /> 
          : null
        }
      </button>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src={config?.logoUrl} alt="logo"/>
      </div>

      <button 
        className={classes.infoButton}
        onClick={() => onInfoButtonClick()}
      ><img src="ui/info-icon.png" alt="logo"/></button>

      <div className={classes.imprintButtonContainer}>
        <button 
          className={classes.imprintButton}
          onClick={() => onImprintButtonClick()}
        ><img src="ui/imprint-icoon.png" alt="logo"/></button>
      </div>

      {!props.descriptionHidden ? (
        <div className={classes.description}>
          <Trans>
            {t(`${props.modelId}.description`)}
          </Trans>
        </div>
      ) : null}

      <div className={classes.modelViewerContainer}>
        <ModelViewerComponent
          ref={modelViewer}
          modelUrl={config?.colorsUrl?.[colorState]!}
          environmentImage={config?.environmentImage}
          onHasARSupport={onHasARSupport}
        ></ModelViewerComponent>
      </div>

      {!props.instructionHidden ? (
        <div className={classes.instruction}>
          <Trans>
            {t(`${props.modelId}.instruction`)}
          </Trans>
        </div>
      ) : null}
      
      {!props.colorPickerHidden ? (
        <div className={classes.colorsPicker}>
          {renderColorButton(ModelColor.BLACK)}
          {renderColorButton(ModelColor.WHITE)}
        </div>
      ) : null}

      {hasARSupport ? (
        <button 
          className={classes.arButton}
          onClick={() => onARButtonClick()}
        >
          <Trans>
            {t(`${props.modelId}.arButton`)}
          </Trans>
        </button>
      ) : null}

      <InfoPopup 
        text={t(`${props.modelId}.infoPopupText`)}
        isOpened={infoPopupIsOpen}
        onClose={onInfoPopupClose}
      ></InfoPopup>

      <InfoPopup 
        text={t(`${props.modelId}.imprint`)}
        isOpened={imprintPopupIsOpen}
        onClose={onImprintPopupClose}
      ></InfoPopup>
    </div>
  );
};

export default AppColors;
