import i18n from 'i18next';

export enum ModelType {
  VC100 = 'VC100',
  VC100_BLACK_ONLY = 'VC100BlackOnly',
  THERMOMIX = 'Thermomix',
  THERMOMIX_WHITE_ONLY = 'ThermomixWhiteOnly',
  THERMOMIX_BLACK_ONLY = 'ThermomixBlackOnly',
  THERMOMIX_SPARKLY_BLACK = 'ThermomixSparklyBlack',
  VB100 = 'VB100',
  VK7_V1 = 'VK7-V1',
  VK7_V2 = 'VK7-V2',
  VK7_V3 = 'VK7-V3',
}

interface IConfig {
  id: ModelType;
  models: IModelConfig[];
}

interface IModelConfig {
  languages: string[];
  logoUrl: string;
  colorsUrl?: {black?: string, white?: string};
  iconsUrl?: {black?: string, white?: string};
  variants?: IVariant[];
  environmentImage: string;
}

export enum ModelColor {
  BLACK = 'black',
  WHITE = 'white'
} 

interface IVariant {
  id: string;
  iconUrl: string;
  modelUrl: string;
}

export interface ILanguage {
  code: string;
  name: string;
}

const languageNames: any = {
  'cs': 'Čeština',
  'de': 'Deutsch',
  'de-at': 'Deutsch (Österreich)',
  'de-ch': 'Deutsch (Schweiz)',
  'en': 'English',
  'en-us': 'English (US)',
  'en-gb': 'English (UK)',
  'es': 'Español',
  'fr': 'Français',
  'fr-ch': 'Français (Suisse)',
  'it': 'Italiano',
  'it-ch': 'Italiano (Svizzera)',
  'pl': 'Polski',
  'pt': 'Português',
  'tr': 'Türkçe',
  'zh': '普通话',
  'zh-tw': '普通话 (Taiwan)',
};

const MODEL_URLS = {
  THERMOMIX: {
    white: 'models/thermomix/mod_tm6cadmodel_white_tm6',
    black: 'models/thermomix/mod_tm6cadmodel_black_tm6',
    sparkly_black: 'models/thermomix/mod_tm6cadmodel_sparklyblack_tm6'
  },
  BIMBY: {
    white: 'models/thermomix/mod_tm6cadmodel_white_bimby',
    black: 'models/thermomix/mod_tm6cadmodel_black_bimby',
    sparkly_black: 'models/thermomix/mod_tm6cadmodel_sparklyblack_bimby'
  },
  VC100_KOBOLD: {
    white: 'models/vc100/mod_vc100_w_k',
    black: 'models/vc100/mod_vc100_b_k'
  },
  VC100_FOLLETTO: {
    white: 'models/vc100/mod_vc100_w_f',
    black: 'models/vc100/mod_vc100_b_f'
  },
  VB100: {
    white: 'models/vb100/mod_vb100_white',
    black: 'models/vb100/mod_vb100_black'
  }
};

export enum VK7_VARIANTS {
  FULL_SYSTEM = 'full-system',
  EB7_MOUNTED = 'eb7-mounted',
  SP7_MOUNTED = 'sp7-mounted',
  EB7_PARK = 'eb7-park',
  SP7_PARK = 'sp7-park',
  RB7 = 'rb7',
}

const vk7VariantConfig: IVariant[] = [
  {
    id: VK7_VARIANTS.FULL_SYSTEM,
    iconUrl: 'ui/vk7/full_system.png',
    modelUrl: 'models/vk7/full_system_kobold'
  },
  {
    id: VK7_VARIANTS.EB7_MOUNTED,
    iconUrl: 'ui/vk7/vk7_eb7_hanging.png',
    modelUrl: 'models/vk7/vk7_eb7_hanging_kobold'
  },
  {
    id: VK7_VARIANTS.SP7_MOUNTED,
    iconUrl: 'ui/vk7/vk7_sp7_wall_mounted.png',
    modelUrl: 'models/vk7/vk7_sp7_hanging_kobold'
  },
  {
    id: VK7_VARIANTS.EB7_PARK,
    iconUrl: 'ui/vk7/vk7_eb7_park_position.png',
    modelUrl: 'models/vk7/vk7_eb7_parked_kobold'
  },
  {
    id: VK7_VARIANTS.SP7_PARK,
    iconUrl: 'ui/vk7/vk7_sp7_park_position.png',
    modelUrl: 'models/vk7/vk7_sp7_parked_kobold'
  },
  {
    id: VK7_VARIANTS.RB7,
    iconUrl: 'ui/vk7/vr7_rb7_base.png',
    modelUrl: 'models/vk7/vr7_kobold'
  },
];

const vk7VariantConfigIT: IVariant[] = [
  {
    id: VK7_VARIANTS.FULL_SYSTEM,
    iconUrl: 'ui/vk7/full_system.png',
    modelUrl: 'models/vk7/full_system_folletto'
  },
  {
    id: VK7_VARIANTS.EB7_MOUNTED,
    iconUrl: 'ui/vk7/vk7_eb7_hanging.png',
    modelUrl: 'models/vk7/vk7_eb7_hanging_folletto'
  },
  {
    id: VK7_VARIANTS.SP7_MOUNTED,
    iconUrl: 'ui/vk7/vk7_sp7_wall_mounted.png',
    modelUrl: 'models/vk7/vk7_sp7_hanging_folletto'
  },
  {
    id: VK7_VARIANTS.EB7_PARK,
    iconUrl: 'ui/vk7/vk7_eb7_park_position.png',
    modelUrl: 'models/vk7/vk7_eb7_parked_folletto'
  },
  {
    id: VK7_VARIANTS.SP7_PARK,
    iconUrl: 'ui/vk7/vk7_sp7_park_position.png',
    modelUrl: 'models/vk7/vk7_sp7_parked_folletto'
  },
  {
    id: VK7_VARIANTS.RB7,
    iconUrl: 'ui/vk7/vr7_rb7_base.png',
    modelUrl: 'models/vk7/vr7_folletto'
  },
];

const getVariant = (id:string, variants: IVariant[]): IVariant => {
  return variants.find(obj => {
    return obj.id === id
  })!;
}


/**
 * Config settings
 */
const config: IConfig[] = [
  {
    id: ModelType.THERMOMIX,
    models: [
      {
        languages: ['cs', 'de', 'de-at', 'de-ch', 'en-gb', 'en-us', 'es', 'fr', 'fr-ch', 'it-ch', 'pl', 'tr', 'zh', 'zh-tw'],
        logoUrl: 'ui/logos/thermomix.png',
        colorsUrl: {
          black: MODEL_URLS.THERMOMIX.black,
          white: MODEL_URLS.THERMOMIX.white,
        },
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      },
      {
        languages: ['it', 'pt'],
        logoUrl: 'ui/logos/bimby.png',
        colorsUrl: {
          black: MODEL_URLS.BIMBY.black,
          white: MODEL_URLS.BIMBY.white,
        },
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      },
    ]
  },
  {
    id: ModelType.THERMOMIX_WHITE_ONLY,
    models: [
      {
        languages: ['de', 'de-at', 'fr'],
        logoUrl: 'ui/logos/thermomix.png',
        colorsUrl: {
          white: MODEL_URLS.THERMOMIX.white,
        },
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      }
    ]
  },
  {
    id: ModelType.THERMOMIX_BLACK_ONLY,
    models: [
      {
        languages: ['fr'],
        logoUrl: 'ui/logos/thermomix.png',
        colorsUrl: {
          black: MODEL_URLS.THERMOMIX.black,
        },
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      }
    ]
  },
  {
    id: ModelType.THERMOMIX_SPARKLY_BLACK,
    models: [
      {
        languages: ['cs', 'de', 'de-at', 'de-ch', 'en-gb', 'en-us', 'es', 'fr', 'fr-ch', 'it-ch', 'pl', 'zh', 'zh-tw'],
        logoUrl: 'ui/logos/thermomix.png',
        colorsUrl: {
          black: MODEL_URLS.THERMOMIX.sparkly_black,
          white: MODEL_URLS.THERMOMIX.white,
        },
        iconsUrl: {
          black: 'ui/sparkly-black.png'
        },
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      },
      {
        languages: ['it', 'pt'],
        logoUrl: 'ui/logos/bimby.png',
        colorsUrl: {
          black: MODEL_URLS.BIMBY.sparkly_black,
          white: MODEL_URLS.THERMOMIX.white,
        },
        iconsUrl: {
          black: 'ui/sparkly-black.png'
        },
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      },
    ]
  },
  {
    id: ModelType.VC100,
    models: [
      {
        languages: ['cs', 'de', 'de-at', 'de-ch', 'en', 'es', 'fr', 'fr-ch', 'it-ch', 'pl', 'pt', 'zh', 'zh-tw'],
        logoUrl: 'ui/logos/kobold.png',
        colorsUrl: {
          black: MODEL_URLS.VC100_KOBOLD.black,
          white: MODEL_URLS.VC100_KOBOLD.white,
        },
        environmentImage: 'neutral'
      },
      {
        languages: ['it'],
        logoUrl: 'ui/logos/folletto.png',
        colorsUrl: {
          black: MODEL_URLS.VC100_FOLLETTO.black,
          white: MODEL_URLS.VC100_FOLLETTO.white,
        },
        environmentImage: 'neutral'
      },
    ]
  },
  {
    id: ModelType.VC100_BLACK_ONLY,
    models: [
      {
        languages: ['fr'],
        logoUrl: 'ui/logos/kobold.png',
        colorsUrl: {
          black: MODEL_URLS.VC100_KOBOLD.black,
        },
        environmentImage: 'neutral'
      }
    ]
  },
  {
    id: ModelType.VB100,
    models: [
      {
        languages: ['cs', 'en-gb', 'de', 'de-at', 'de-ch', 'es', 'fr', 'fr-ch', 'it-ch', 'pl', 'pt', 'zh', 'zh-tw'],
        logoUrl: 'ui/logos/kobold.png',
        colorsUrl: {
          black: MODEL_URLS.VB100.black,
          white: MODEL_URLS.VB100.white,
        },
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      }
    ]
  },
  {
    id: ModelType.VK7_V1,
    models: [
      {
        languages: ['cs', 'de', 'de-at', 'de-ch', 'en-gb', 'es', 'fr', 'fr-ch', 'it-ch', 'pl', 'zh', 'zh-tw'],
        logoUrl: 'ui/logos/kobold.png',
        variants: [
          getVariant(VK7_VARIANTS.FULL_SYSTEM, vk7VariantConfig),
          getVariant(VK7_VARIANTS.EB7_MOUNTED, vk7VariantConfig),
          getVariant(VK7_VARIANTS.SP7_MOUNTED, vk7VariantConfig),
          getVariant(VK7_VARIANTS.EB7_PARK, vk7VariantConfig),
          getVariant(VK7_VARIANTS.SP7_PARK, vk7VariantConfig),
          getVariant(VK7_VARIANTS.RB7, vk7VariantConfig),
        ],
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      },
      {
        languages: ['it'],
        logoUrl: 'ui/logos/folletto.png',
        variants: [
          getVariant(VK7_VARIANTS.FULL_SYSTEM, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.EB7_MOUNTED, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.SP7_MOUNTED, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.EB7_PARK, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.SP7_PARK, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.RB7, vk7VariantConfigIT),
        ],
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      },
    ]
  },
  {
    id: ModelType.VK7_V2,
    models: [
      {
        languages: ['cs', 'de', 'de-at', 'de-ch', 'en-gb', 'es', 'fr', 'fr-ch', 'it-ch', 'pl', 'zh', 'zh-tw'],
        logoUrl: 'ui/logos/kobold.png',
        variants: [
          getVariant(VK7_VARIANTS.EB7_MOUNTED, vk7VariantConfig),
          getVariant(VK7_VARIANTS.SP7_MOUNTED, vk7VariantConfig),
          getVariant(VK7_VARIANTS.EB7_PARK, vk7VariantConfig),
          getVariant(VK7_VARIANTS.SP7_PARK, vk7VariantConfig),
        ],
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      },
      {
        languages: ['it'],
        logoUrl: 'ui/logos/folletto.png',
        variants: [
          getVariant(VK7_VARIANTS.EB7_MOUNTED, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.SP7_MOUNTED, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.EB7_PARK, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.SP7_PARK, vk7VariantConfigIT),
        ],
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      },
    ]
  },
  {
    id: ModelType.VK7_V3,
    models: [
      {
        languages: ['cs', 'de', 'de-at', 'de-ch', 'en-gb', 'es', 'fr', 'fr-ch', 'it-ch', 'pl', 'zh', 'zh-tw'],
        logoUrl: 'ui/logos/kobold.png',
        variants: [
          getVariant(VK7_VARIANTS.SP7_PARK, vk7VariantConfig),
          getVariant(VK7_VARIANTS.EB7_PARK, vk7VariantConfig),
          getVariant(VK7_VARIANTS.SP7_MOUNTED, vk7VariantConfig),
          getVariant(VK7_VARIANTS.EB7_MOUNTED, vk7VariantConfig),
          getVariant(VK7_VARIANTS.FULL_SYSTEM, vk7VariantConfig),
        ],
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      },
      {
        languages: ['it'],
        logoUrl: 'ui/logos/folletto.png',
        variants: [
          getVariant(VK7_VARIANTS.SP7_PARK, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.EB7_PARK, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.SP7_MOUNTED, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.EB7_MOUNTED, vk7VariantConfigIT),
          getVariant(VK7_VARIANTS.FULL_SYSTEM, vk7VariantConfigIT),
        ],
        environmentImage: 'models/thermomix/aircraft_workshop_01_1k.hdr'
      },
    ]
  },
];

export const getModelConfig = (id: string, lang: string): IModelConfig => {
  const modelConfig = config.find(obj => {
      return obj.id === id
    })!.models.find(obj => {
      return obj.languages.includes(lang)
    }) as IModelConfig;

  return modelConfig;
}

export const getModelLanguages = (id: string): ILanguage[] => {
  let availableModelLanguages: string[] = [];
  let languagesResult: ILanguage[] = [];
  
  config.find(obj => {
    return obj.id === id
  })!.models.forEach((modelItem) => {
    availableModelLanguages.push(...modelItem.languages);
  });

  // FIND Duplicates in available languages and throw console ERROR
  if ( 
    availableModelLanguages.some((item, index) => index !== availableModelLanguages.indexOf(item))
  ) {
    console.error(`${id} contains duplicate languages`); 
  }

  // sort concatanted array of available language
  availableModelLanguages.sort();

  availableModelLanguages.forEach((lang) => {
    languagesResult.push(
      {
        code: lang,
        name: languageNames[lang]
      }
    );
  });

  return languagesResult;
}