import { FC, useState } from 'react';
import LocaleSelector from './views/locale-selector';
import AppColors from './views/app-colors';
import AppVariants from './views/app-variants';
import i18n from 'i18next';
import { ModelColor, ModelType, getModelLanguages } from './config';

export interface IAppLoaderProps {
  modelId: string;
}

const AppLoader: FC<IAppLoaderProps> = (props: IAppLoaderProps) => {
  const [langSelected, setLangSelected] = useState<string>('');

  const onLanguageSubmit = (lang: string) => {
    i18n.changeLanguage(lang, () => {
      setLangSelected(lang);
    });
  };

  const getContent = () => {
    if(langSelected === '') {
      return (
        <LocaleSelector 
          onSubmit={onLanguageSubmit}
          languages={getModelLanguages(props.modelId)}>
        </LocaleSelector>
      );
    } else {
      switch (props.modelId) {
        case ModelType.THERMOMIX:
        case ModelType.VB100:
        case ModelType.VC100:
          return (
            <AppColors 
              modelId={props.modelId}
              language={langSelected}>
            </AppColors>
          );
        case ModelType.THERMOMIX_WHITE_ONLY:
          return (
            <AppColors 
              modelId={props.modelId}
              language={langSelected}
              color={ModelColor.WHITE}
              viewDEWhiteOnly
              colorPickerHidden
              descriptionHidden
              instructionHidden>
            </AppColors>
          );
        case ModelType.THERMOMIX_BLACK_ONLY:
          return (
            <AppColors 
              modelId={props.modelId}
              language={langSelected}
              color={ModelColor.BLACK}
              colorPickerHidden
              descriptionHidden
              instructionHidden>
            </AppColors>
          );
        case ModelType.THERMOMIX_SPARKLY_BLACK:
          return (
            <AppColors 
              modelId={props.modelId}
              language={langSelected}>
            </AppColors>
          );
        case ModelType.VC100_BLACK_ONLY:
          return (
            <AppColors 
              modelId={props.modelId}
              language={langSelected}
              color={ModelColor.BLACK}
              colorPickerHidden
              descriptionHidden
              instructionHidden>
            </AppColors>
          );
        case ModelType.VK7_V1:
        case ModelType.VK7_V2:
        case ModelType.VK7_V3:
          return (
            <AppVariants 
              modelId={props.modelId}
              language={langSelected}
            >
            </AppVariants>
          );
      }
    }
  };
  
  return (
    <>
      {getContent()}
    </>
  );
};

export default AppLoader;
