import { FC, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { getModelConfig } from '../config';
import ModelViewerComponent from '../components/model-viewer-component';
import InfoPopup from '../components/info-popup';
import { Trans } from 'react-i18next';
import theme, { SecondaryButton } from '../theme';

export interface IAppVariantsProps {
  modelId: string;
  language: string;
}

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: `0 ${theme.padding}px ${theme.padding}px`,
    textAlign: 'center',
  },
  logo: {
    marginTop: `${theme.paddingTop}px`,
    '& img': {
      height: '70px'
    },
  },
  infoButton: {
    width: `${theme.buttonWidth}px`,
    height: `${theme.buttonHeight}px`,
    position: 'absolute',
    top: `${theme.paddingTop}px`,
    right: `${theme.padding}px`,
    border: '0',
    borderRadius: theme.buttonBorderRadius,
    backgroundColor: theme.vorwerkColor,
    '@media (max-width: 390px)': {
      width: theme.smallerButtonWidth,
      height: theme.smallerButtonHeight,
    },
    "& img": {
      width: '10px',
      marginTop: '2px'
    }
  },
  imprintButtonContainer: {
    position: 'absolute',
    width: '100%',
    textAlign: 'right',
    top: `${theme.buttonWidth + theme.paddingTop*2 - 5}px`,
    right: `${theme.padding}px`,
    '@media (max-width: 390px)': {
      top: `${theme.buttonWidth + theme.paddingTop*2 - 12}px`,
    },
  },
  imprintButton: {
    extend: SecondaryButton,
    width: `${theme.buttonWidth}px`,
    height: `${theme.buttonHeight}px`,
    '@media (max-width: 390px)': {
      width: theme.smallerButtonWidth,
      height: theme.smallerButtonHeight,
    },
    "& img": {
      width: '24px',
      marginTop: '2px'
    }
  },
  description: {
    marginTop: '40px',
    fontSize: '18px',
    fontFamily: 'Vorwerk',
    fontWeight: 'lighter',
    '@media (max-height: 630px)': {
      marginTop: '30px',
      fontSize: '16px',
    },
  },
  instruction: {
    marginTop: '18px',
    padding: '0 12px',
    fontSize: '17px',
    fontFamily: 'Vorwerk',
    '@media (max-height: 630px)': {
      fontSize: '16px',
    },
  },
  variantsPicker: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    '& button': {
      width: '50px',
      height: '50px',
      border: '1px solid #000',
      borderRadius: '50%',
      background: 'white',
      '@media (max-width: 390px)': {
        width: '40px',
        height: '40px',
      },
      "& img": {
        width: '100%',
      },
    },
    '& button:not(:first-child)': {
      marginLeft: "14px"
    }
  },
  variantButtonSelected: {
    border: `2px solid ${theme.vorwerkColor} !important`
  },
  arButton: {
    display: 'flex',
    marginTop: '20px',
    flexDirection: 'column',
    maxWidth: '100%',
    padding: '8px 18px',
    border: '0',
    borderRadius: theme.buttonBorderRadius,
    color: 'white',
    fontFamily: 'Vorwerk',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: theme.vorwerkColor,
    '@media (max-height: 630px)': {
      padding: '6px 16px',
      fontSize: '16px',
    },
  },
  modelViewerContainer: {
    width: '100%',
    height: '20%',
    flex: '1',
    marginTop: '15px',
  }
});

const AppVariants: FC<IAppVariantsProps> = (props: IAppVariantsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [variantState, setVariantState] = useState<number>(0);
  const [infoPopupIsOpen, setInfoPopupIsOpen] = useState<boolean>(false);
  const [imprintPopupIsOpen, setImprintPopupIsOpen] = useState<boolean>(false);
  const [hasARSupport, setHasARSupport] = useState<boolean>(false);

  const config = getModelConfig(props.modelId, props.language);
  const variants = config.variants;
  const localeKey = props.modelId.split('-')[0];
  
  const onVariantButtonClick = (index: number) => {
    console.log(index);
    setVariantState(index);
  };

  const onInfoButtonClick = () => {
    setInfoPopupIsOpen(true);
  };

  const onInfoPopupClose = () => {
    setInfoPopupIsOpen(false);
  };

  const onImprintButtonClick = () => {
    setImprintPopupIsOpen(true);
  };

  const onImprintPopupClose = () => {
    setImprintPopupIsOpen(false);
  };

  const modelViewer = useRef<any>();
  const onARButtonClick = () => {
    modelViewer.current.activateAR();
  };

  const onHasARSupport = () => {
    setHasARSupport(true);
  }

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src={config?.logoUrl} alt="logo"/>
      </div>

      <button 
        className={classes.infoButton}
        onClick={() => onInfoButtonClick()}
      ><img src="ui/info-icon.png" alt="logo"/></button>

      <div className={classes.imprintButtonContainer}>
        <button 
          className={classes.imprintButton}
          onClick={() => onImprintButtonClick()}
        ><img src="ui/imprint-icoon.png" alt="logo"/></button>
      </div>

      <div className={classes.description}>
        <Trans>
          {t(`${localeKey}.description`)}
        </Trans>
      </div>

      <div className={classes.modelViewerContainer}>
        <ModelViewerComponent
          ref={modelViewer}
          modelUrl={variants![variantState].modelUrl}
          environmentImage={config?.environmentImage}
          onHasARSupport={onHasARSupport}
        ></ModelViewerComponent>
      </div>

      <div className={classes.instruction}>
        <Trans>
          {t(`${localeKey}.instruction`)}
        </Trans>
      </div>
      
      <div className={classes.variantsPicker}>
        {config?.variants?.map((item, index) => (
          <button 
            key={index}
            className={index === variantState ? classes.variantButtonSelected : ''}
            onClick={() => onVariantButtonClick(index)}
          >
            <img src={item.iconUrl} alt={`variant${index}`} />
          </button>
        ))}
      </div>

      {hasARSupport ? (
        <button 
          className={classes.arButton}
          onClick={() => onARButtonClick()}
        >
          <Trans>
            {t(`${localeKey}.arButton`)}
          </Trans>
        </button>
      ) : null}

      <InfoPopup 
        text={t(`${localeKey}.infoPopupText`)}
        isOpened={infoPopupIsOpen}
        onClose={onInfoPopupClose}
      ></InfoPopup>

      <InfoPopup 
        text={t(`${localeKey}.imprint`)}
        isOpened={imprintPopupIsOpen}
        onClose={onImprintPopupClose}
      ></InfoPopup>
    </div>
  );
};

export default AppVariants;
