import { CSSProperties } from 'react';

const theme = {
  padding: 30,
  paddingTop: 15,
  buttonBorderRadius: '4px',
  buttonWidth: 44,
  buttonHeight: 44,
  smallerButtonWidth: '38px',
  smallerButtonHeight: '38px',
  vorwerkColor: '#00AC46',
  textPrimaryColor: '#23282A',
  textAccentColor: '#FFF',
};

export const SecondaryButton: CSSProperties = {
  border: `1.5px solid ${theme.vorwerkColor}`,
  borderRadius: theme.buttonBorderRadius,
  backgroundColor: '#FFF',
  color: theme.vorwerkColor,
};

export const SecondaryButtonNoBorder: CSSProperties = {
  border: '0',
  color: theme.vorwerkColor,
  backgroundColor: 'white',
  fontWeight: 'bold',
};

export default theme;
