import { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { ILanguage } from '../config';
import theme from '../theme';

const themeComp = {
  padding: 40,
  paddingTop: 15,
};

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: `0 ${themeComp.padding}px ${themeComp.padding}px`,
    textAlign: 'center',
  },
  logo: {
    marginTop: `${themeComp.paddingTop}px`,
    '& img': {
      height: '55px'
    },
  },
  selectorContainer: {
    width: '100%',
    marginTop: '25px',
  },
  selectorLabel: {

  },
  dropdownContainer: {
    marginTop: '25px',
    display: 'flex',
    flexDirection: 'row',
  },
  dropdown : {
    flex: "1",
    padding: '18px 26px',
    borderTopLeftRadius: theme.buttonBorderRadius,
    borderBottomLeftRadius: theme.buttonBorderRadius,
    appearance: 'none',
    border: '0',
    backgroundColor: '#f8f8f8',
    color: '#009a3b',
    fontFamily: 'Vorwerk',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMyAxNiI+CiAgICA8cGF0aCBmaWxsPSIjMDA5QTNEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOS43ODggNi44NzZsMi4yNzEtMi4yOTJhMy4yNjEgMy4yNjEgMCAwIDAgMC00LjU4NGwtMy45NzIgNC4wMDgtNi41ODcgNi42NDYtNi4wMTYtNi4wNy0uNTctLjU3NkwuOTQgMGEzLjI2MiAzLjI2MiAwIDAgMCAwIDQuNTg0TDIuNjQgNi4zbC41NzEuNTc2IDYuMDE3IDYuMDcgMi4yNzEgMi4yOTIgOC4yODgtOC4zNjJ6Ii8+Cjwvc3ZnPgo=)',
    backgroundPosition: 'right .7em top 50%,0 0',
    backgroundSize: '.65em auto,100%',
    backgroundRepeat: 'no-repeat,repeat',
  },
  submitButton: {
    padding: '20px',
    border: 'none',
    borderRadius: `0 ${theme.buttonBorderRadius} ${theme.buttonBorderRadius} 0`,
    backgroundColor: '#009a3b',
    color: 'white',
    fontFamily: 'Vorwerk',
    fontSize: '16px',
    fontWeight: 'bold',
  }
});
 

const LocaleSelector: FC<{
  onSubmit: (lang: string) => void,
  languages: ILanguage[]
}> = (props) => {
  const classes = useStyles();

  const sysLang = navigator.language.split('-')[0]
  const isSysLang = props.languages.find(lang => {
    return lang.code === sysLang
  });
  const [localeSelected, setLocaleSelected] = useState<string>(
    isSysLang ? sysLang : props.languages[0].code
  );
  
  const handleChange = (event: any) => {
    setLocaleSelected(event.target.value);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    props.onSubmit(localeSelected);
  }

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src="ui/logos/vorwerk.png" alt="logo"/>
      </div>
      <form className={classes.selectorContainer} onSubmit={handleSubmit}>
        <label className={classes.selectorLabel}>
          Please select your language
        </label>
        <div className={classes.dropdownContainer}>
          <select 
            className={classes.dropdown} 
            value={localeSelected} 
            onChange={handleChange}
          >
            {props.languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>
          <button 
            className={classes.submitButton} 
            type="submit" 
          >Go</button>
        </div>
      </form>
    </div>
  );
};

export default LocaleSelector;
