import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import theme, { SecondaryButtonNoBorder } from '../theme';

interface IInfoPopupProps {
  text: any;
  isOpened: boolean;
  onClose: any;
}

const themeComp = {
  marginTopBottom: 60,
  marginLeftRight: 30
};

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, .7);',
    zIndex: 990,
  },
  popupBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: 'absolute',
    padding: '18px',
    boxSizing: 'border-box',
    width: `calc(100% - 2*${themeComp.marginLeftRight}px)`,
    maxHeight: `calc(100% - 2*${themeComp.marginTopBottom}px)`,
    marginTop: themeComp.marginTopBottom,
    marginLeft: themeComp.marginLeftRight,
    backgroundColor: 'white',
    color: theme.textPrimaryColor,
    borderRadius: theme.buttonBorderRadius,
    fontFamily: 'Vorwerk',
  },
  popupContent: {
    width: '100%',
    height: '100%',
    flex: '1',
    marginTop: "15px",
    textAlign: "left",
    overflow: 'scroll'
  },
  closeButton: {
    extend: SecondaryButtonNoBorder,
    padding: '0',
    fontSize: '16px',
  },
  closedPopup: {
    visibility: 'hidden',
  }
});


const InfoPopup: FC<IInfoPopupProps> = (props: IInfoPopupProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  const onCloseClick = () => {
    props.onClose();
  };

  let containerClass = '';
  if (!props.isOpened) {
    containerClass = `${classes.container} ${classes.closedPopup}`;
  } else {
    containerClass = classes.container;
  }


  return (
    <div className={containerClass}>
      <div className={classes.popupBox}>
        <button className={classes.closeButton} onClick={onCloseClick}>
          {t('close')}
        </button>
        <div className={classes.popupContent}>
          <Trans>
            {props.text}
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default InfoPopup;
