import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ModelType } from './config';
import AppLoader from './app-loader';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/thermomix-limited">
          <AppLoader modelId={ModelType.THERMOMIX}></AppLoader>
        </Route>
        <Route path="/thermomix-tm6">
          <AppLoader modelId={ModelType.THERMOMIX_WHITE_ONLY}></AppLoader>
        </Route>
        <Route path="/tm6-black">
          <AppLoader modelId={ModelType.THERMOMIX_BLACK_ONLY}></AppLoader>
        </Route>
        <Route path="/tm6-sparkling-black">
          <AppLoader modelId={ModelType.THERMOMIX_SPARKLY_BLACK}></AppLoader>
        </Route>
        <Route path="/vc100">
          <AppLoader modelId={ModelType.VC100}></AppLoader>
        </Route>
        <Route path="/vc100-black">
          <AppLoader modelId={ModelType.VC100_BLACK_ONLY}></AppLoader>
        </Route>
        <Route path="/vb100">
          <AppLoader modelId={ModelType.VB100}></AppLoader>
        </Route>
        <Route path="/vk7system">
          <AppLoader modelId={ModelType.VK7_V1}></AppLoader>
        </Route>
        <Route path="/vk7s">
          <AppLoader modelId={ModelType.VK7_V2}></AppLoader>
        </Route>
        <Route path="/vk7syst">
          <AppLoader modelId={ModelType.VK7_V3}></AppLoader>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
